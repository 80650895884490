<template>
  <div class="persons-table">
    <div class="text-lg bold title">
      <slot name="title"></slot>
    </div>
    <div class="table">
      <div class="table-header table-row bold text-md" v-if="showHeader">
        <div class="cell cell-name">Full Name</div>
        <div class="cell">Relation</div>
      </div>
      <div v-for="person in persons" :key="person.code" class="table-row text-md">
        <div class="cell text-color cell-name">
          <slot name="cell-name" :person="person"></slot>
        </div>
        <div class="cell is-flex" :class="{concealed: isClassified}">
          <slot name="cell-relation" :person="person"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    persons: Array,
    isClassified: {type: Boolean, default: false},
    showHeader: {type: Boolean, default: false},
  },
  name: 'PersonsTable',
};
</script>

<style lang="scss" scoped>
.persons-table {
  .title {
    color: $neutral-600;
    margin-bottom: 8px;
  }

  .table {
    display: flex;
    flex-direction: column;
    border: 1px solid $neutral-200;
    .table-row {
      display: flex;
      background: white;
      border-top: 1px solid $neutral-200;
      .cell {
        width: 100%;
        padding: 8px 0 8px 16px;
        color: $neutral-600;
        text-align: left;

        &.cell-name {
          max-width: 155px;
        }

        &.is-flex {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          text-transform: capitalize;
          row-gap: 4px;
        }
        .flex-align-center {
          display: flex;
          align-items: center;
        }
        .tooltip-account-icon {
          margin-right: 5px;
          color: $neutral-400;
        }
      }
      .text-color {
        color: $text-color;
      }

      &:last-of-type {
        border-radius: 0 0 4px 4px;
      }
    }
    .table-header {
      border-top: none;
      background: $background-light;
      border-radius: 4px 4px 0 0;
    }
  }
}
</style>
