<template>
  <div class="filters-full-text">
    <div v-if="noFilters" class="supplemental">No filters to apply.</div>
    <filter-item
      label="All Categories"
      :options="categoriesOptions"
      :cut-count="filterCutCount"
      :hide-header-count="true"
      @select="selectCategoryId"
      @deselect="deSelectType('category_id')"
    ></filter-item>

    <filter-item
      label="All Publication Locations"
      :options="countryOptions"
      :cut-count="filterCutCount"
      @select="selectType($event.object_id, 'country')"
      @deselect="deSelectType('country')"
    ></filter-item>

    <filter-item
      label="All Ancestral Places"
      :options="ancestralPlacesOptions"
      :cut-count="100"
      @select="selectTypeAncestralPlace"
      @deselect="deSelectTypeAncestralPlace"
    ></filter-item>

    <filter-item
      label="All Chinese Surnames"
      :options="clansOptions"
      :cut-count="filterCutCount"
      :force-cut="true"
      @select="selectType($event.name_hant || $event.name_ch || $event.object_id, 'clan_name')"
      @deselect="deSelectType('clan_name')"
    ></filter-item>

    <filter-item
      label="All Record Types"
      :options="sourceTypeOptions"
      :cut-count="filterCutCount"
      @select="selectType($event.object_id, 'source_type')"
      @deselect="deSelectType('source_type')"
    ></filter-item>
  </div>
</template>

<script>
import {isChineseText} from '@common/utils/utils';
import orderBy from 'lodash/orderBy';

import FilterItem from './FilterItem';

export default {
  props: {
    facets: Object,
    noneValue: String,
    sourceTypes: Array,
    formData: Object,
    totalCount: Number,
  },
  data() {
    return {
      filterCutCount: 5,
    };
  },
  computed: {
    countDisplay() {
      return this.totalCount >= 10000 ? '10000+' : this.totalCount;
    },
    countryOptions() {
      if (!this.facets.country) {
        return [];
      }
      let options = this.facets.country;
      const formCountry = this.formData.country;
      if (formCountry && !options.find(item => item.object_id === formCountry)) {
        options = [{name: formCountry, object_id: formCountry, count: 0}, ...options];
      }
      return this.normalizeOptions(options, 'country');
    },
    categoriesOptions() {
      if (!this.facets.category_id) {
        return [];
      }
      let options = Object.values(this.facets.category_id);
      const formCategoryId = this.formData.category_id;
      if (formCategoryId && !options.find(item => item.id === formCategoryId)) {
        options = [{name: formCategoryId, explicit_name: formCategoryId, id: formCategoryId, count: 0}, ...options];
      }
      return this.normalizeOptions(options, 'category_id', 'id', 'priority', 'asc');
    },
    sourceTypeOptions() {
      if (!this.facets.source_type) {
        return [];
      }
      let options = this.facets.source_type;
      const formSourceType = this.formData.source_type;
      if (formSourceType && !options.find(item => item.object_id === formSourceType)) {
        const item = this.sourceTypes.find(item => item.value === formSourceType);
        options = item ? [{name: item.name, object_id: item.value, count: 0}, ...options] : options;
      }
      return this.normalizeOptions(options, 'source_type');
    },
    ancestralPlacesOptions() {
      if (!this.facets.ancestral_level_1_id) {
        return [];
      }
      const selectedLevel1 = this.formData.ancestral_level_1 ? this.formData.ancestral_level_1.id : null;
      const selectedLevel2 = this.formData.ancestral_level_2 ? this.formData.ancestral_level_2.id : null;
      const selectedLevel3 = this.formData.ancestral_level_3 ? this.formData.ancestral_level_3.id : null;
      const ancestralPlaceId = this.formData.ancestral_place ? this.formData.ancestral_place.id : null;
      const ancestralPlaceItem = ancestralPlaceId
        ? {
            isSelected: true,
            name: this.formData.ancestral_place.address_en,
            object_id: ancestralPlaceId,
            count: this.countDisplay,
            ignoreCount: true,
            selectField,
            deselectFields,
          }
        : null;
      let selectedLevel1Present = false;
      let selectedLevel2Present = false;
      let selectedLevel3Present = false;
      let selectField = 'ancestral_level_3';
      let deselectFields = ['ancestral_place'];
      let level4places =
        ancestralPlaceId && selectedLevel3 && ancestralPlaceId !== selectedLevel3 ? [ancestralPlaceItem] : [];
      let level3places = this.facets.ancestral_level_3_id.map(item => {
        const selected = item.object_id == selectedLevel3;
        selectedLevel3Present = selectedLevel3Present || selected;
        return {
          ...item,
          isSelected: selected && !level4places.length,
          children: selected ? level4places : [],
          selectField,
          deselectFields,
        };
      });
      level3places = this.normalizeOptions(level3places, 'ancestral_level_3_id');
      if (!selectedLevel3Present && selectedLevel3) {
        level3places.unshift({
          isSelected: true,
          name: this.formData.ancestral_level_3.address_en,
          object_id: selectedLevel3,
          count: 0,
          children: level4places,
          selectField,
          deselectFields,
        });
      }
      selectField = 'ancestral_level_2';
      deselectFields = ['ancestral_level_3', 'ancestral_place'];
      let level2places = this.facets.ancestral_level_2_id.map(item => {
        const selected = item.object_id == selectedLevel2;
        selectedLevel2Present = selectedLevel2Present || selected;
        return {
          ...item,
          isSelected: selected && !selectedLevel3,
          children: selected ? level3places : [],
          selectField,
          deselectFields,
        };
      });
      level2places = this.normalizeOptions(level2places, 'ancestral_level_2_id');
      if (!selectedLevel2Present && selectedLevel2) {
        level2places.unshift({
          isSelected: true,
          name: this.formData.ancestral_level_2.address_en,
          object_id: selectedLevel2,
          count: 0,
          children: level3places,
          selectField,
          deselectFields,
        });
      }
      selectField = 'ancestral_level_1';
      deselectFields = ['ancestral_level_3', 'ancestral_level_2', 'ancestral_place'];
      let level1places = this.facets.ancestral_level_1_id.map(item => {
        const selected = item.object_id == selectedLevel1;
        selectedLevel1Present = selectedLevel1Present || selected;
        return {
          ...item,
          isSelected: selected && !selectedLevel2,
          children: selected ? level2places : [],
          selectField,
          deselectFields,
        };
      });
      level1places = this.normalizeOptions(level1places, 'ancestral_level_1_id');
      if (!selectedLevel1Present && selectedLevel1) {
        level1places.unshift({
          isSelected: true,
          name: this.formData.ancestral_level_1.address_en,
          object_id: selectedLevel1,
          count: 0,
          children: level2places,
          selectField,
          deselectFields,
        });
      }
      if (
        ancestralPlaceId &&
        !level4places.length &&
        ancestralPlaceId !== selectedLevel1 &&
        ancestralPlaceId !== selectedLevel2 &&
        ancestralPlaceId !== selectedLevel3
      ) {
        const arr = level2places.length ? level2places : level1places;
        arr.unshift(ancestralPlaceItem);
      }
      return level1places;
    },
    clansOptions() {
      if (!this.facets.clans) {
        return [];
      }
      let options = this.facets.clans.map(clan => {
        const nameCh =
          clan.name_hant && clan.name_ch !== clan.name_hant ? `${clan.name_ch} / ${clan.name_hant}` : clan.name_ch;
        const name = [clan.pinyin || '', nameCh].join(' ').trim() || clan.name;
        return {...clan, name};
      });
      const formClanName = this.formData.clan_name;
      if (formClanName && !options.find(item => this.isTypeItemClanNameSelected(item, formClanName))) {
        const nameDisplay = isChineseText(formClanName) ? formClanName : `${formClanName} (All Translations)`;
        options = [{name_ch: formClanName, name: nameDisplay, count: this.countDisplay, ignoreCount: true}, ...options];
      }
      return this.normalizeOptions(options, 'clan_name', 'name_ch', 'count', 'desc');
    },
    noneName() {
      return 'None';
    },
    noFilters() {
      return (
        !this.countryOptions.length &&
        !this.sourceTypeOptions.length &&
        !this.categoriesOptions.length &&
        !this.ancestralPlacesOptions.length &&
        !this.clansOptions.length
      );
    },
  },
  methods: {
    isTypeItemSelected(item, typeName) {
      const mapping = {
        clan_name: this.isTypeItemClanNameSelected,
        ancestral_place: this.isTypeItemAncestralPlaceSelected,
        category_id: this.isTypeItemByIdSelected,
      };
      const method = mapping[typeName];
      const selectedValue = this.formData[typeName];
      return method ? method(item, selectedValue) : item.object_id === selectedValue;
    },
    isTypeItemClanNameSelected(item, selectedValue) {
      if (!selectedValue) {
        return false;
      }
      return ['name_ch', 'name_hant', 'object_id'].some(field => item[field] === selectedValue);
    },
    isTypeItemByIdSelected(item, selectedValue) {
      return selectedValue ? selectedValue === item.id : false;
    },
    isTypeItemAncestralPlaceSelected(item, selectedValue) {
      return selectedValue ? item.object_id == selectedValue.id : false;
    },
    selectType(value, typeName, deselectFields) {
      this.$emit('select', {typeName, value, deselectFields});
    },
    deSelectType(typeName) {
      this.$emit('select', {typeName, value: null});
    },
    selectCategoryId(value) {
      this.selectType(value.id, 'category_id');
    },
    selectTypeAncestralPlace(value) {
      this.selectType(
        {address_en: value.name, full_address_en: value.name, id: value.object_id},
        value.selectField,
        value.deselectFields
      );
    },
    deSelectTypeAncestralPlace(value) {
      if (value.selectField) {
        this.deSelectType(value.selectField);
      } else {
        this.selectType(null, null, ['ancestral_level_3', 'ancestral_level_2', 'ancestral_level_1']);
      }
    },
    normalizeOptions(options, typeName, idField = 'object_id', sortByField = 'name', orders = 'asc') {
      let result = [];
      let noneValue = null;
      let noneValueIndex = -1;
      let selectedValue = null;
      let selectedValueIndex = -1;
      let index = -1;
      const ordered = orderBy(options, [sortByField], [orders]);
      for (let item of ordered) {
        const isNoneItem = item.object_id === this.noneValue;
        index += 1;

        if (this.isTypeItemSelected(item, typeName)) {
          const name = isNoneItem ? this.noneName : item.name;
          selectedValue = {...item, isSelected: true, name};
          selectedValueIndex = isNoneItem ? 0 : index;
          continue;
        }

        if (isNoneItem) {
          noneValue = item;
          noneValueIndex = index;
          continue;
        }
        result.push(item);
      }

      if (noneValue) {
        result.unshift({...noneValue /*name: this.noneName*/});
      }
      if (selectedValue && selectedValueIndex >= 0) {
        selectedValueIndex = noneValueIndex > selectedValueIndex ? selectedValueIndex + 1 : selectedValueIndex;
        selectedValueIndex = selectedValueIndex >= this.filterCutCount ? 0 : selectedValueIndex;
        result.splice(selectedValueIndex, 0, selectedValue);
      }
      return result;
    },
  },
  components: {FilterItem},
};
</script>

<style lang="scss" scoped></style>
