<template>
  <div class="filters-full-text">
    <div v-if="noFilters" class="supplemental">No filters to apply.</div>
    <filter-item
      label="All Categories"
      :options="categoriesOptions"
      :cut-count="filterCutCount"
      :hide-header-count="true"
      @select="selectType($event.id, 'category_id')"
      @deselect="deSelectType('category_id')"
    ></filter-item>

    <filter-item
      label="All Source Locations"
      :options="sourceLocationOptions"
      :cut-count="filterCutCount"
      @select="selectTypeSourceLocation"
      @deselect="deSelectType('source_location')"
    ></filter-item>

    <filter-item
      label="All Genders"
      :options="genderOptions"
      :cut-count="filterCutCount"
      @select="selectType($event.object_id, 'gender')"
      @deselect="deSelectType('gender')"
    ></filter-item>

    <filter-item
      label="All Chinese Surnames"
      :options="clansOptions"
      :cut-count="filterCutCount"
      :force-cut="true"
      @select="selectType($event.name_hant || $event.name_ch || $event.object_id, 'clan_name')"
      @deselect="deSelectType('clan_name')"
    ></filter-item>

    <filter-item
      label="All Dates"
      :options="datesOptions"
      :total-count="facets.record_year && facets.record_year.total_count"
      :cut-count="100"
      @select="selectTypeDates"
      @deselect="deSelectTypeDates"
    ></filter-item>

    <filter-item
      label="All Image Availabilities"
      :options="hasImagesOptions"
      :total-count="facets.has_images && facets.has_images.total_count"
      :cut-count="100"
      @select="selectType($event.object_id, 'has_images')"
      @deselect="deSelectType('has_images')"
    ></filter-item>

    <filter-item
      label="All Record Types"
      :options="sourceTypeOptions"
      :cut-count="filterCutCount"
      @select="selectType($event.object_id, 'source_types')"
      @deselect="deSelectType('source_types')"
    ></filter-item>
  </div>
</template>

<script>
import consts from '@common/utils/consts';
import {isChineseText} from '@common/utils/utils';
import orderBy from 'lodash/orderBy';

import FilterItem from './FilterItem';

export default {
  props: {
    facets: Object,
    noneValue: String,
    sourceTypes: Array,
    sourceCountries: Array,
    formData: Object,
    totalCount: Number,
  },
  data() {
    return {
      filterCutCount: 5,
    };
  },
  computed: {
    countDisplay() {
      return this.totalCount >= 10000 ? '10000+' : this.totalCount;
    },
    sourceLocationOptions() {
      let options = this.facets.country || [];
      const placeId = this.formData.source_location ? this.formData.source_location.id : null;
      if (placeId && !options.find(item => item.object_id == placeId)) {
        const placeParent = this.formData.source_location.parents
          ? this.formData.source_location.parents.find(parent => parent.level === 0)
          : null;
        const optionParent = placeParent ? options.find(item => item.object_id == placeParent.id) : null;
        const selectedItem = {
          name: this.formData.source_location.address_en,
          object_id: placeId,
          count: this.countDisplay,
          isSelected: true,
          ignoreCount: true,
        };
        if (optionParent) {
          optionParent.children = [selectedItem];
        } else {
          options = [selectedItem, ...options];
        }
      }
      return this.normalizeOptions(options, 'source_location');
    },
    sourceTypeOptions() {
      if (!this.facets.source_type) {
        return [];
      }
      let options = this.facets.source_type;
      const formSourceType = this.formData.source_types;
      if (formSourceType && !options.find(item => item.object_id === formSourceType)) {
        const item = this.sourceTypes.find(item => item.value === formSourceType);
        options = item ? [{name: item.name, object_id: item.value, count: 0}, ...options] : options;
      }
      return this.normalizeOptions(options, 'source_types');
    },
    categoriesOptions() {
      if (!this.facets.category_id) {
        return [];
      }
      let options = Object.values(this.facets.category_id);
      const formCategoryId = this.formData.category_id;
      if (formCategoryId && !options.find(item => item.id === formCategoryId)) {
        options = [{name: formCategoryId, explicit_name: formCategoryId, id: formCategoryId, count: 0}, ...options];
      }
      return this.normalizeOptions(options, 'category_id', 'id', 'priority', 'asc');
    },
    genderOptions() {
      if (!this.facets.gender) {
        return [];
      }
      const mapping = consts.GENDERS;
      let options = this.facets.gender;
      const formGender = this.formData.gender;
      if (formGender && !options.find(item => item.object_id === formGender)) {
        options = [{name: mapping[formGender], object_id: formGender, count: 0}, ...options];
      }
      return options.map(gender => {
        return {...gender, name: mapping[gender.object_id], isSelected: this.isTypeItemSelected(gender, 'gender')};
      });
    },
    noneName() {
      return 'None';
    },
    noFilters() {
      return (
        !this.sourceLocationOptions.length &&
        !this.sourceTypeOptions.length &&
        !this.categoriesOptions.length &&
        !this.genderOptions.length &&
        !this.clansOptions.length &&
        !this.hasImagesOptions.length
      );
    },
    clansOptions() {
      if (!this.facets.clans) {
        return [];
      }
      let options = this.facets.clans.map(clan => {
        const nameCh =
          clan.name_hant && clan.name_ch !== clan.name_hant ? `${clan.name_ch} / ${clan.name_hant}` : clan.name_ch;
        const name = [clan.pinyin || '', nameCh].join(' ').trim() || clan.name;
        return {...clan, name};
      });
      const formClanName = this.formData.clan_name;
      if (formClanName && !options.find(item => this.isTypeItemClanNameSelected(item, formClanName))) {
        const nameDisplay = isChineseText(formClanName) ? formClanName : `${formClanName} (All Translations)`;
        options = [{name_ch: formClanName, name: nameDisplay, count: this.countDisplay, ignoreCount: true}, ...options];
      }
      return this.normalizeOptions(options, 'clan_name', 'name_ch', 'count', 'desc');
    },
    datesOptions() {
      if (!this.facets.record_year) {
        return [];
      }
      const selectedYear = this.formData.record_year;

      let isSelectedPresent = false;
      let centuries = [...this.facets.record_year.items];

      centuries.sort(this.compareDates);
      centuries = centuries.map(century => {
        let decades = century.children || [];
        let expandCentury = selectedYear == century.object_id;
        decades = decades.map(childDecade => {
          let expandDecade = selectedYear == childDecade.object_id;
          expandCentury = expandCentury || expandDecade;
          let years = childDecade.children || [];
          years = years.map(childYear => {
            let yearSelected = selectedYear == childYear.object_id;
            expandCentury = expandCentury || yearSelected;
            expandDecade = expandDecade || yearSelected;
            return {...childYear, isSelected: yearSelected};
          });
          years.sort(this.compareDates);
          return {
            ...childDecade,
            isSelected: selectedYear == childDecade.object_id,
            children: expandDecade ? years : [],
          };
        });
        decades.sort(this.compareDates);
        isSelectedPresent = isSelectedPresent || expandCentury;
        return {...century, isSelected: selectedYear == century.object_id, children: expandCentury ? decades : []};
      });

      if (!isSelectedPresent && selectedYear) {
        const value = selectedYear;
        const name = value.includes('-') ? value.split('-')[1] + 's' : value;
        centuries.unshift({isSelected: true, name, object_id: value, count: 0});
      }
      return centuries;
    },
    hasImagesOptions() {
      if (!this.facets.has_images) {
        return [];
      }
      const mapping = {true: 'Images Available', false: 'No images available'};
      let options = this.facets.has_images;
      const formData = this.formData.has_images;
      if (formData && !options.find(item => item.object_id === formData)) {
        options = [{name: mapping[formData], object_id: formData, count: 0}, ...options];
      }
      options.sort(item => (item.object_id === 'true' ? -1 : 1));
      return options.map(item => {
        return {...item, name: mapping[item.object_id], isSelected: this.isTypeItemSelected(item, 'has_images')};
      });
    },
  },
  methods: {
    compareDates(a, b) {
      if (a.key === 'none') {
        return -1;
      }
      return a.key - b.key;
    },
    isTypeItemSelected(item, typeName) {
      const mapping = {
        clan_name: this.isTypeItemClanNameSelected,
        source_location: this.isTypeItemPlaceSelected,
        category_id: this.isTypeItemByIdSelected,
      };
      const method = mapping[typeName];
      const selectedValue = this.formData[typeName];
      return method ? method(item, selectedValue) : item.object_id == selectedValue;
    },
    isTypeItemClanNameSelected(item, selectedValue) {
      if (!selectedValue) {
        return false;
      }
      return ['name_ch', 'name_hant', 'object_id'].some(field => item[field] === selectedValue);
    },
    isTypeItemByIdSelected(item, selectedValue) {
      return selectedValue ? selectedValue === item.id : false;
    },
    isTypeItemPlaceSelected(item, selectedValue) {
      return selectedValue ? item.object_id == selectedValue.id : false;
    },
    selectType(value, typeName) {
      this.$emit('select', {typeName, value});
    },
    deSelectType(typeName) {
      this.$emit('select', {typeName, value: null});
    },
    selectTypeSourceLocation(value) {
      return this.selectType(
        {address_en: value.name, full_address_en: value.name, id: value.object_id},
        'source_location'
      );
    },
    selectTypeDates(value) {
      this.selectType(value.object_id, 'record_year');
    },
    deSelectTypeDates(value) {
      this.deSelectType('record_year');
    },
    normalizeOptions(options, typeName, idField = 'object_id', sortByField = 'name', orders = 'asc') {
      let result = [];
      let noneValue = null;
      let noneValueIndex = -1;
      let selectedValue = null;
      let selectedValueIndex = -1;
      let index = -1;
      const ordered = orderBy(options, [sortByField], [orders]);
      for (let item of ordered) {
        const isNoneItem = item.object_id === this.noneValue;
        index += 1;

        if (this.isTypeItemSelected(item, typeName)) {
          const name = isNoneItem ? this.noneName : item.name;
          selectedValue = {...item, isSelected: true, name};
          selectedValueIndex = isNoneItem ? 0 : index;
          continue;
        }

        if (isNoneItem) {
          noneValue = item;
          noneValueIndex = index;
          continue;
        }
        result.push(item);
      }

      if (noneValue) {
        result.unshift({...noneValue /*name: this.noneName*/});
      }
      if (selectedValue && selectedValueIndex >= 0) {
        selectedValueIndex = noneValueIndex > selectedValueIndex ? selectedValueIndex + 1 : selectedValueIndex;
        selectedValueIndex = selectedValueIndex >= this.filterCutCount ? 0 : selectedValueIndex;
        result.splice(selectedValueIndex, 0, selectedValue);
      }
      return result;
    },
  },
  components: {FilterItem},
  name: 'FiltersIndexed',
};
</script>

<style scoped></style>
