<template>
  <div class="sources-form advanced-form unified-search-form" v-if="isAdvanced">
    <template v-if="!isZupusCategory">
      <div class="filter form-item">
        <label class="input-label">Keyword</label>
        <input
          v-capitalize
          :value="formData.q"
          :placeholder="keywordPlaceholder"
          :class="{'has-error': errors.q}"
          class="full-width input"
          @input="onInputChange('q', $event)"
          @keyup.enter="onInputEnter"
        />
      </div>
      <place-input
        class="filter form-item"
        label="Place (Source Location or Ancestral Place)"
        :placeholder="autoFieldPlaceholder"
        :disabled="autoFieldDisabled"
        :value="formData.auto_place || {}"
        :show-default-options="false"
        :only-ancestral-places="false"
        :error="errors.auto_place"
        multiselect-classes="bordered"
        label-classes="input-label"
        @select="onPlaceSelect"
      ></place-input>

      <div class="text-lg section-heading">Source Filters</div>
    </template>

    <div class="filter form-item">
      <label for="location" class="input-label">
        <mcr-wiki-glossary-link class="help-text" naturalId="db-source-location" :is-help-text="true">
          Source Location
        </mcr-wiki-glossary-link>
      </label>
      <single-option-select
        id="location"
        placeholder="All Locations"
        value-field="object_id"
        :value="formData.country"
        :options="countriesOptions"
        @select="onValueChange('country', $event)"
      ></single-option-select>
    </div>

    <div class="filter form-item">
      <label for="source_clan" class="input-label">
        <mcr-wiki-glossary-link class="help-text" naturalId="last-name-filter" :is-help-text="true">
          Source Clan
        </mcr-wiki-glossary-link>
      </label>
      <surname-input
        :show-default-label="true"
        :value="surnameInputValue"
        :allow-spelling-select="true"
        @select="onSourceClanChange"
      ></surname-input>
    </div>

    <div class="filter form-item">
      <label for="ancestral_place" class="input-label">
        <mcr-wiki-glossary-link naturalId="ancestral-place-filter" :is-help-text="true">
          Source Ancestral Place
        </mcr-wiki-glossary-link>
      </label>
      <place-input
        label=""
        :value="formData.ancestral_place || {}"
        :show-default-options="false"
        :only-ancestral-places="true"
        :error="errors.ancestral_place"
        multiselect-classes="bordered"
        @select="onAncestralPlaceSelect"
      ></place-input>
    </div>

    <div class="filter form-item">
      <label class="input-label">Publication Year</label>
      <input
        :value="formData.publication_year"
        placeholder="YYYY"
        class="full-width input"
        :class="{'has-error': errors.publication_year}"
        @input="onInputChange('publication_year', $event)"
        @keyup.enter="onInputEnter"
      />
      <div v-if="errors.publication_year" class="error">{{ errors.publication_year }}</div>
    </div>

    <div class="filter form-item">
      <label for="source-types" class="input-label">Source Type</label>
      <single-option-select
        id="source-types"
        placeholder="All Source Types"
        :value="formData.source_type"
        :value-field="sourceTypeValueField"
        :options="sourceTypeOptions"
        @select="onValueChange('source_type', $event)"
      ></single-option-select>
    </div>

    <div class="filter form-item">
      <label for="source-types" class="input-label">Format</label>
      <single-option-select
        id="formats"
        placeholder="All Formats"
        :value="formData.record_format"
        value-field="object_id"
        :options="formatOptions"
        @select="onValueChange('record_format', $event)"
      ></single-option-select>
    </div>

    <div class="filter form-item">
      <label for="source-types" class="input-label">Full Text Available</label>
      <single-option-select
        id="has_ocr"
        placeholder="All Sources"
        :value="formData.has_ocr"
        :options="hasOcrOptions"
        @select="onValueChange('has_ocr', $event)"
      ></single-option-select>
    </div>

    <div class="filter form-item">
      <label for="categories" class="input-label">Category</label>
      <single-option-select
        id="categories"
        placeholder="All Categories"
        value-field="id"
        :value="formData.category_id"
        :options="categoriesOptions"
        @select="onValueChange('category_id', $event)"
      ></single-option-select>
    </div>

    <div class="button-container">
      <mcr-button v-if="showCancel" class="white" @click="$emit('cancel')">Cancel</mcr-button>
      <mcr-button class="search-button" @click="onSearchClick">
        <search-icon class="search-icon" :size="20"></search-icon>
        <span>Search</span>
      </mcr-button>
    </div>
  </div>

  <div class="sources-form simple-form unified-search-form" v-else-if="isZupusCategory">
    <div class="filter-group align-bottom">
      <div class="filter">
        <label for="source_clan" class="input-label">Surname</label>
        <surname-input
          :show-default-label="true"
          :value="surnameInputValue"
          :allow-spelling-select="true"
          @select="onSourceClanChange"
        ></surname-input>
      </div>
      <div class="filter">
        <label for="ancestral_place" class="input-label">Ancestral Place</label>
        <place-input
          label=""
          :value="formData.ancestral_place || {}"
          :show-default-options="false"
          :only-ancestral-places="true"
          :error="errors.ancestral_place"
          multiselect-classes="bordered"
          @select="onAncestralPlaceSelect"
        ></place-input>
      </div>
      <mcr-button class="search-button" @click="onSearchClick">
        <search-icon class="search-icon" :size="20"></search-icon>
        <span>Search</span>
      </mcr-button>
    </div>
  </div>

  <div class="sources-form simple-form unified-search-form" v-else>
    <div class="filter-group align-bottom">
      <div class="filter">
        <label class="input-label">Keyword</label>
        <input
          v-capitalize
          :value="formData.q"
          :placeholder="keywordPlaceholder"
          :class="{'has-error': errors.q}"
          class="input"
          @input="onInputChange('q', $event)"
          @keyup.enter="onInputEnter"
        />
      </div>
      <place-input
        class="filter"
        label="Place"
        :value="formData.auto_place || {}"
        :show-default-options="false"
        :only-ancestral-places="false"
        :error="errors.auto_place"
        multiselect-classes="bordered"
        label-classes="input-label"
        @select="onPlaceSelect"
      ></place-input>
      <mcr-button class="search-button" @click="onSearchClick">
        <search-icon class="search-icon" :size="20"></search-icon>
        <span>Search</span>
      </mcr-button>
    </div>
  </div>
</template>

<script>
import mcrButton from '@common/elements/buttons/mcrButton';
import SingleOptionSelect from '@common/elements/filters/SingleOptionSelect';
import PlaceInput from '@common/elements/inputs/PlaceInput';
import SurnameInput from '@common/elements/inputs/SurnameInput';
import {sortBy} from 'lodash';
import isEmpty from 'lodash/isEmpty';
import SearchIcon from 'vue-material-design-icons/Magnify';

const mcrWikiGlossaryLink = () => import('@common/elements/glossary/mcrWikiGlossaryLink');

export default {
  props: {
    initFormData: {type: Object},
    meta: {type: Object},
    sourceTypes: {type: Array},
    initAdvanced: {type: Boolean, default: false},
    showCancel: {type: Boolean, default: false},
    isZupusCategory: {type: Boolean, default: false},
  },
  data() {
    return {
      formData: {...this.initFormData},
      errors: {},
      isAdvanced: this.initAdvanced,
    };
  },
  computed: {
    autoFieldDisabled() {
      const ancestralPlaceId = this.formData.ancestral_place && this.formData.ancestral_place.id;
      return Boolean(this.formData.country || ancestralPlaceId);
    },
    autoFieldPlaceholder() {
      if (this.autoFieldDisabled) {
        return 'Clear the place selections below to use this field';
      }
      return '';
    },
    keywordPlaceholder() {
      if (this.$route.name === 'search-burial-records') {
        return 'Teochew Cemetery';
      }
      return 'Chinese in Hawaii';
    },
    countriesOptions() {
      return this.meta && this.meta.country ? sortBy(this.meta.country, 'name') : [];
    },
    surnameInputValue() {
      const options = this.meta ? this.meta.clans || [] : [];
      const clan = this.formData.clan_name
        ? options.find(clan =>
            ['name_ch', 'name_hant', 'object_id'].some(field => clan[field] === this.formData.clan_name)
          )
        : null;
      return clan ? clan : {name_ch: this.formData.clan_name};
    },
    sourceTypeOptions() {
      return this.sourceTypes ? [...this.sourceTypes].sort((a, b) => a.name.localeCompare(b.name)) : [];
    },
    categoriesOptions() {
      const categories = this.$store.getters.searchAllRecordsOptionsState.categories;
      return categories ? [...categories].sort((a, b) => a.priority - b.priority) : [];
    },
    sourceTypeValueField() {
      if (this.sourceTypes && this.sourceTypes[0] && this.sourceTypes[0].object_id) {
        return 'object_id';
      }
      return 'value';
    },
    formatOptions() {
      return this.meta && this.meta.record_format ? sortBy(this.meta.record_format, 'name') : [];
    },
    hasOcrOptions() {
      return [
        {name: 'All Sources', value: null},
        {name: 'Yes', value: 'true'},
        {name: 'No', value: 'false'},
      ];
    },
  },
  methods: {
    onSearchClick() {
      const isValid = this.validateFilters();
      if (isValid) {
        this.runSearch();
      }
    },
    validateFilters() {
      this.errors = {};
      if (this.checkIsNan(this.formData.publication_year)) {
        this.errors['publication_year'] = 'A number is required';
      }
      return isEmpty(this.errors);
    },
    runSearch() {
      this.$emit('submit', this.formData);
    },
    onInputChange(fieldName, event) {
      this.onValueChange(fieldName, event.target.value.trim());
    },
    onPlaceSelect(value) {
      this.onValueChange('auto_place', value);
    },
    onValueChange(fieldName, fieldValue) {
      this.$set(this.formData, fieldName, fieldValue);
      if (['ancestral_place', 'country'].includes(fieldName) && fieldValue) {
        this.$set(this.formData, 'auto_place', {});
      }
    },
    onSourceClanChange(value) {
      this.onValueChange('clan_name', value.name_hant || value.name_ch);
    },
    onAncestralPlaceSelect(value) {
      this.onValueChange('ancestral_place', value);
      const hierarchy = value.parents ? sortBy([value, ...value.parents], 'level') : [];
      let ancestral_level_1 = hierarchy.length > 1 ? hierarchy[1] : null;
      ancestral_level_1 = value.id == process.env.VUE_APP_TAIWAN_ID ? value : ancestral_level_1;
      const ancestral_level_2 = hierarchy.length > 2 ? hierarchy[2] : null;
      const ancestral_level_3 = hierarchy.length > 3 ? hierarchy[3] : null;
      this.onValueChange('ancestral_level_1', ancestral_level_1);
      this.onValueChange('ancestral_level_2', ancestral_level_2);
      this.onValueChange('ancestral_level_3', ancestral_level_3);
    },
    onInputEnter() {
      this.onSearchClick();
    },
    setIsAdvanced(value) {
      this.isAdvanced = value;
    },
    checkIsNan(value) {
      return value && isNaN(+value);
    },
    mutateFormData(newObject) {
      Object.assign(this.formData, newObject);
    },
    initSwitchLang() {
      // used by FormTabsBlockContainer
    },
  },
  components: {
    SearchIcon,
    PlaceInput,
    mcrButton,
    SingleOptionSelect,
    SurnameInput,
    mcrWikiGlossaryLink,
  },
  name: 'SourcesForm',
};
</script>

<style lang="scss" scoped>
@import '../styles/form.scss';
</style>
