<template>
  <div class="record-details-table">
    <details-item label="Other on this Record" v-if="otherMentions.length">
      <router-link
        class="name"
        v-for="person in shownOtherMentions"
        :to="getRelatedPersonRoute(person)"
        :key="person.id"
        >{{ person.full_name || 'Unknown' }}</router-link
      >
      <span v-if="moreOtherMentionsLength">
        and {{ moreOtherMentionsLength }} more persons (<a @click="showAllOtherMentions">show all</a>)
      </span>
    </details-item>
    <details-item label="Surname (en)" v-if="surnamesEn.length">
      <span class="name" v-for="name in surnamesEn" :key="name.value">
        <router-link :to="getSurnameRoute(name.value)">{{ name.value }}</router-link>
        <span v-if="name.type_display"> ({{ name.type_display }})</span>
      </span>
    </details-item>
    <details-item label="Given name (en)" v-if="firstNamesEn.length">
      <span class="name" v-for="name in firstNamesEn">{{ name.value }}</span>
    </details-item>
    <details-item label="Surname (cn)" v-if="surnamesCh.length">
      <span class="name" v-for="name in surnamesCh" :key="name.value">
        <router-link :to="getSurnameRoute(name.value)">{{ name.value }}</router-link>
        <span v-if="name.type_display"> ({{ name.type_display }})</span>
      </span>
    </details-item>
    <details-item label="Given name (cn)" v-if="firstNamesCh.length">
      <span class="name" v-for="name in firstNamesCh">{{ name.value }}</span>
    </details-item>
    <details-item label="Other names" v-if="otherNames.length">
      <span class="name" v-for="name in otherNames">{{ name.value }}</span>
    </details-item>
    <details-item label="Birth place" v-if="birthLocation && birthLocation.display_text">
      <router-link v-if="birthLocationRoute" :to="birthLocationRoute">{{ birthLocation.display_text }}</router-link>
      <span v-else>{{ birthLocation.display_text }}</span>
    </details-item>
    <details-item label="Death place" v-if="deathLocation && deathLocation.display_text">
      <router-link v-if="deathLocationRoute" :to="deathLocationRoute">{{ deathLocation.display_text }}</router-link>
      <span v-else>{{ deathLocation.display_text }}</span>
    </details-item>
    <details-item label="Sex" v-if="genderDisplay && genderDisplay !== 'Unknown'"
      ><span>{{ genderDisplay }}</span></details-item
    >
    <details-item label="Birth date" v-if="birthDateFormatted"
      ><span>{{ birthDateFormatted }}</span></details-item
    >
    <details-item label="Death date" v-if="deathDateFormatted"
      ><span>{{ deathDateFormatted }}</span></details-item
    >
    <details-item label="Residence" v-if="residenceLocation && residenceLocation.length">
      <div v-for="residence in residenceLocation" class="name">
        <router-link v-if="residence.place_id" :to="getResidenceRoute(residence)">{{
          getFormattedResidenceText(residence)
        }}</router-link>
        <span v-else>{{ getFormattedResidenceText(residence) }}</span>
      </div>
    </details-item>
    <details-item label="Occupation" v-if="occupation"
      ><span>{{ occupation }}</span></details-item
    >
    <details-item label="Generation" wiki-id="generation-number" v-if="generationNumber"
      ><span>{{ generationNumber }}</span></details-item
    >

    <template v-for="(data, key) in normalizedAdditionalInfo">
      <details-item v-if="data.value && data.value.length" :key="key" :label="data.label">
        <span v-for="item in data.value" class="name">
          <router-link :to="item.route" v-if="item.route">{{ item.name }}</router-link>
          <span v-else>{{ item.name }}</span>
        </span>
      </details-item>
    </template>
    <template v-for="fact in factsDisplay">
      <details-item :label="fact.factType.label" :key="fact.id">
        <div>
          <span>{{ fact.value }}{{ fact.valueSeparator }}</span>
          <span>{{ fact.dates }}{{ fact.placeSeparator }}</span>
          <router-link :to="fact.placeRoute" v-if="fact.placeRoute">{{ fact.placeText }}</router-link>
          <span v-else>{{ fact.placeText }}</span>
        </div>
        <a
          v-if="source.external_source_url && fact.factType.id === 'original_title'"
          :href="source.external_source_url"
          class="supplemental external-link"
          target="_blank"
        >
          <span>View record details on partner site</span>
          <link-icon class="link-icon" :size="18"></link-icon>
        </a>
      </details-item>
      <details-item label="Cemetery" class="sub-item" v-if="fact.cemetery" :key="fact.id + 'cemetery'">
        <router-link :key="fact.cemetery.object_id" :to="getCemeteryRoute(fact.cemetery)">{{
          fact.cemetery.name
        }}</router-link>
      </details-item>
      <details-item
        v-for="infoItem in fact.info"
        :label="infoItem.label"
        :key="fact.id + 'info' + infoItem.key"
        class="sub-item"
      >
        <span>{{ infoItem.value }}</span>
      </details-item>
    </template>
    <details-item label="Source" v-if="showSource"
      ><router-link :to="sourceRoute">{{ sourceTitle }}</router-link></details-item
    >
    <details-item label="Zupu Clan" v-if="source.clan_pedigree_id && source.clans && source.clans.length">
      <link-surname-page-family :clans="source.clans" :show-family="false"></link-surname-page-family>
    </details-item>
    <details-item
      label="Zupu Place"
      v-if="source.clan_pedigree_id && source.ancestral_places && source.ancestral_places.length"
    >
      <link-place-page v-for="place in source.ancestral_places" :key="place.id" :place="place" class="name">{{
        place.pinyin
      }}</link-place-page>
    </details-item>
    <details-item :label="publicationYearLabel" v-if="showSource && source.publication_year">
      {{ source.publication_year }}
    </details-item>
    <details-item label="Cemetery" v-if="cemeteries && cemeteries.length">
      <router-link
        v-for="cemetery in cemeteries"
        class="name"
        :key="cemetery.object_id"
        :to="getCemeteryRoute(cemetery)"
        >{{ cemetery.name }}</router-link
      >
    </details-item>
    <details-item label="Association" v-if="associations && associations.length">
      <span v-for="association in associations" class="name" :key="association.id">{{ association.name_en }}</span>
    </details-item>
  </div>
</template>

<script>
import detailsItem from '@common/elements/layouts/detailsItem';
import LinkPlacePage from '@common/elements/links/linkPlacePage';
import linkSurnamePageFamily from '@common/elements/links/linkSurnamePageFamily';
import {sourceTypes} from '@common/utils/consts.source';
import {formatApproximateDate, getFormattedResidenceText} from '@common/utils/utils';
import {getCemeteryDetailRoute, getPersonRecordDetailRoute, getPlaceDetailRoute} from '@common/utils/utils.routes';
import LinkIcon from 'vue-material-design-icons/OpenInNew';

export default {
  props: {
    firstNamesEn: Array,
    surnamesEn: Array,
    firstNamesCh: Array,
    surnamesCh: Array,
    otherNames: Array,
    birthLocation: Object,
    deathLocation: Object,
    residenceLocation: Array,
    birthDate: Object,
    deathDate: Object,
    genderDisplay: String,
    occupation: String,
    generationNumber: [Number, String],
    additionalInfo: Object,
    otherMentions: Array,

    associations: Array,
    cemeteries: Array,
    source: Object,
    facts: Array,
  },
  created() {
    this.processAdditionalInfo(this.additionalInfo);
  },
  data() {
    return {
      normalizedAdditionalInfo: {},
      otherMentionsTrimmed: true,
      otherMentionsTrimmedLength: 10,
    };
  },
  computed: {
    birthLocationRoute() {
      if (this.birthLocation && this.birthLocation.place_id) {
        return getPlaceDetailRoute(this.birthLocation.place_id, '');
      }
    },
    deathLocationRoute() {
      if (this.deathLocation && this.deathLocation.place_id) {
        return getPlaceDetailRoute(this.deathLocation.place_id, '');
      }
    },
    birthDateFormatted() {
      return this.birthDate ? formatApproximateDate(this.birthDate) : '';
    },
    deathDateFormatted() {
      return this.deathDate ? formatApproximateDate(this.deathDate) : '';
    },
    shownOtherMentions() {
      if (this.otherMentionsTrimmed) {
        return this.otherMentions.slice(0, this.otherMentionsTrimmedLength);
      }
      return this.otherMentions;
    },
    moreOtherMentionsLength() {
      return this.otherMentions.length - this.shownOtherMentions.length;
    },
    showSource() {
      return this.source.type !== sourceTypes.CEMETERY;
    },
    sourceTitle() {
      const {title_en, title_ch, time_period_start, time_period_end} = this.source;
      const title = `${title_en || ''} ${title_ch || ''}`.trim();
      if (this.source.is_external) {
        const years =
          time_period_start || time_period_end ? `${time_period_start || '...'}-${time_period_end || '...'}` : '';
        const yearsStr = years ? `, ${years}` : '';
        return `${title}${yearsStr}`;
      }
      return title;
    },
    sourceRoute() {
      if (this.source.clan_pedigree_id) {
        return {name: 'search-tool-zupu-detail', params: {zupuId: this.source.clan_pedigree_id}};
      }
      return {name: 'source-detail', params: {sourceId: this.source.id}};
    },
    factsDisplay() {
      return this.facts
        ? this.facts.map(fact => {
            const dates = this.getDisplayFactDates(fact.start_date, fact.end_date);
            return {
              id: fact.id,
              factType: fact.fact_type,
              info: fact.info,
              value: fact.value || '',
              valueSeparator: fact.value && (dates || fact.place_display_text) ? ', ' : '',
              dates: dates,
              placeText: fact.place_display_text || '',
              placeRoute: fact.place_id ? getPlaceDetailRoute(fact.place_id, fact.place_display_text) : null,
              placeSeparator: dates && fact.place_display_text ? ' in ' : '',
            };
          })
        : [];
    },
    publicationYearLabel() {
      return this.source.clan_pedigree_id ? 'Zupu Publication Year' : 'Source Date';
    },
  },
  methods: {
    getPlaceDetailRoute,
    getSurnameRoute(word) {
      return {name: 'search-tool-surname-detail', query: {word}};
    },
    getCemeteryRoute(cemetery) {
      return getCemeteryDetailRoute(cemetery.object_id, cemetery.name);
    },
    getRelatedPersonRoute(person) {
      return getPersonRecordDetailRoute(person.code, person.full_name);
    },
    getResidenceRoute(residence) {
      return getPlaceDetailRoute(residence.place_id, residence.display_text);
    },
    getFormattedResidenceText: getFormattedResidenceText,
    processAdditionalInfo(info) {
      const config = {};
      for (let [key, data] of Object.entries(info)) {
        let value = data.value;
        if (!value.length) {
          continue;
        }
        if (!key.includes('business')) {
          continue;
        }
        if (typeof value[0] === 'string') {
          value = value.map(text => ({name: text}));
        }
        if (key.endsWith('_place')) {
          value = value
            .filter(item => !!item.name)
            .map(item => {
              return item.place_id ? {...item, route: getPlaceDetailRoute(item.place_id, item.name)} : item;
            });
        }
        config[key] = {...data, value};
      }
      this.normalizedAdditionalInfo = config;
    },
    showAllOtherMentions() {
      this.otherMentionsTrimmed = false;
    },
    getDisplayFactDates(date1, date2) {
      const date1f = date1 ? formatApproximateDate(date1) : '';
      const date2f = date2 ? formatApproximateDate(date2) : '';
      if (date1f && date2f) {
        return `${date1f} to ${date2f}`;
      }
      return date1f || date2f;
    },
  },
  components: {detailsItem, LinkIcon, LinkPlacePage, linkSurnamePageFamily},
  name: 'RecordDetailsTable',
};
</script>

<style lang="scss" scoped>
.record-details-table .name:not(:last-child)::after {
  content: ', ';
}

div + .external-link {
  margin-top: 10px;
}

.external-link {
  display: inline-block;
  .link-icon {
    display: inline;
    margin-left: 4px;
    &::v-deep .material-design-icon__svg {
      margin-top: -3px;
      vertical-align: middle;
    }
  }
}
.details-item.sub-item::v-deep {
  .label {
    padding-left: 27px;
    width: 140px;
  }
}
</style>
