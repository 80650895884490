<template>
  <persons-table class="unit-table" :persons="unit.persons" :is-classified="isClassified">
    <template v-slot:title>
      <span :class="{concealed: isClassified}">{{ unit.name }}</span> Members
    </template>
    <template v-slot:cell-name="{person}">
      <div v-if="isClassified" :class="{concealed: isClassified}">{{ person.full_name }}</div>
      <template v-else>
        <div v-if="isCurrentPerson(person)">{{ person.full_name }}</div>
        <div v-else>
          <router-link :to="getPersonRoute(person)">{{ person.full_name }}</router-link>
        </div>
      </template>
    </template>
    <template v-slot:cell-relation="{person}">
      <div class="flex-align-center">
        <tooltip-account-icon v-if="person.is_head" :size="22" /><span>{{ person.connection_name }}</span>
      </div>
    </template>
  </persons-table>
</template>

<script>
import PersonsTable from '@common/pages/personRecords/PersonsTable';
import {getPersonRecordDetailRoute} from '@common/utils/utils.routes';
import TooltipAccountIcon from 'vue-material-design-icons/TooltipAccount';

export default {
  props: {
    unit: Object,
    isClassified: {type: Boolean, default: false},
    recordType: {type: String},
  },
  methods: {
    getPersonRoute(person) {
      return getPersonRecordDetailRoute(person.code, person.full_name);
    },
    isCurrentPerson(person) {
      return this.$route.params.code === person.code;
    },
  },
  components: {TooltipAccountIcon, PersonsTable},
  name: 'UnitTable',
};
</script>

<style lang="scss" scoped></style>
