<template>
  <div class="form-display">
    <div class="filter-display">
      <div class="bold">Your Search</div>
      <a class="edit-link" @click="$emit('click-edit')"><pencil-icon class="edit-icon" :size="20"></pencil-icon>Edit</a>
    </div>
    <hr />

    <div class="filters">
      <div v-for="filter in filters" class="filter-display">
        <span class="supplemental">{{ filter.label }}</span>
        <div class="filter-value">
          <div v-for="value in filter.values">{{ value }}</div>
        </div>
      </div>
    </div>

    <div class="filter-display">
      <mcr-wiki-glossary-link naturalId="record-status" :is-help-text="true" text="Searching" />
      <div class="filter-value">
        {{ recordStatus }}
      </div>
    </div>
  </div>
</template>

<script>
import {TAB_ID_FULL_TEXT, TAB_ID_INDEXED_RECORDS, TAB_ID_SOURCES} from '@common/utils/consts.search';
import PencilIcon from 'vue-material-design-icons/Pencil';

const mcrWikiGlossaryLink = () => import('@common/elements/glossary/mcrWikiGlossaryLink');

export default {
  props: {
    activeTab: String,
    filters: Array,
  },
  computed: {
    recordStatus() {
      const mapping = {
        [TAB_ID_INDEXED_RECORDS]: 'Indexed',
        [TAB_ID_FULL_TEXT]: 'Full Text',
        [TAB_ID_SOURCES]: 'Sources',
      };
      return mapping[this.activeTab] || this.activeTab;
    },
  },
  components: {PencilIcon, mcrWikiGlossaryLink},
  name: 'FormDisplay',
};
</script>

<style lang="scss" scoped>
.form-display {
  .edit-icon {
    margin-right: 5px;
  }
  &::v-deep .filter-display {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .filter-value {
      max-width: 50%;
      word-break: break-word;
      text-align: end;
    }
  }
  .edit-link {
    display: flex;
  }
}
</style>
