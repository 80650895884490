<template>
  <persons-table class="relatives-table" :persons="allRelatives" :is-classified="isClassified">
    <template v-slot:title>
      <span>Relatives</span>
    </template>
    <template v-slot:cell-name="{person}">
      <div v-if="isClassified" :class="{concealed: isClassified}">{{ person.full_name }}</div>
      <template v-else>
        <div v-if="person.code">
          <router-link :to="getPersonRoute(person)">{{ person.full_name }}</router-link>
        </div>
        <div v-else>{{ person.full_name }}</div>
      </template>
    </template>
    <template v-slot:cell-relation="{person}">
      <span v-capitalize>{{ person.relation }} {{ person.relation_type }}</span>
      <div class="start-event text-sm" v-if="person.start_date || person.start_place">
        {{ getStartEventData(person) }}
      </div>
      <div class="end-event text-sm" v-if="person.end_date || person.end_place || person.is_ended">
        {{ getEndEventData(person) }}
      </div>
    </template>
  </persons-table>
</template>

<script>
import PersonsTable from '@common/pages/personRecords/PersonsTable';
import {formatApproximateDate} from '@common/utils/utils';
import {getFullNameCn, getFullNameEn} from '@common/utils/utils.names';
import {getPersonRecordDetailRoute} from '@common/utils/utils.routes';

export default {
  props: {
    relatives: Array,
    relativesJson: Array,
    isClassified: {type: Boolean, default: false},
  },
  computed: {
    allRelatives() {
      return [...(this.relatives || []), ...this.getRelativesJsonFormatted()];
    },
    locale() {
      return 'en';
    },
  },
  methods: {
    getRelativesJsonFormatted() {
      return this.relativesJson
        ? this.relativesJson.map(item => {
            return {...item, full_name: this.getRelativeName(item)};
          })
        : [];
    },
    getPersonRoute(person) {
      return getPersonRecordDetailRoute(person.code, person.full_name);
    },
    getPlaceDateDisplay(place, date) {
      if (!place && !date) {
        return '';
      }
      if (!date) {
        return place;
      }
      let dateDisplay = formatApproximateDate(date);
      if (place) {
        return `${dateDisplay} in ${place}`;
      }
      return dateDisplay;
    },
    getStartEventData(person) {
      const name = person.relation_category === 'spouses' ? 'Married' : '';
      const placeDateDisplay = this.getPlaceDateDisplay(person.start_place, person.start_date);
      return placeDateDisplay ? `${name}: ${placeDateDisplay}` : '';
    },
    getEndEventData(person) {
      const name = person.relation_category === 'spouses' ? 'Divorced' : '';
      const placeDateDisplay = this.getPlaceDateDisplay(person.end_place, person.end_date);
      return placeDateDisplay ? `${name}: ${placeDateDisplay}` : name;
    },
    getRelativeName(relative) {
      const firstNames = relative.first_name ? [{value: relative.first_name}] : [];
      const surnames = relative.surname ? [{value: relative.surname}] : [];
      return getFullNameEn(firstNames, surnames) || getFullNameCn(firstNames, surnames);
    },
  },
  components: {PersonsTable},
  name: 'RelativesTable',
};
</script>

<style lang="scss" scoped>
.relatives-table {
  .table {
    .table-row {
      .cell {
        .start-event,
        .end-event {
          color: $neutral-400;
          display: flex;
        }
      }
    }
  }
}
</style>
