<template>
  <div class="burial-location-select">
    <multiselect
      :value="value"
      id="burial-location"
      ref="burial-location"
      :placeholder="placeholder"
      :searchable="true"
      :internal-search="false"
      :preserveSearch="false"
      :clearOnSelect="true"
      :show-no-options="true"
      :show-no-results="searchRan"
      :hide-selected="false"
      :options="options"
      track-by="id"
      label="name"
      openDirection="bottom"
      selectLabel=""
      deselectLabel="Selected"
      :loading="burialLocationsLoadingState"
      class="bordered"
      @search-change="search"
      @select="onSelect"
      @close="clearOptions"
    >
      <close-icon
        v-if="value.id && !isMultiselectOpened()"
        slot="caret"
        @mousedown.prevent.stop="clear()"
        class="caret-icon"
      ></close-icon>
      <span slot="noResult" class="input-helper">Location not found</span>
      <span slot="noOptions" class="input-helper">Start typing to search...</span>
      <span slot="singleLabel" slot-scope="props">
        <template>{{ props.option.name || 'All Cemeteries' }}</template>
      </span>
    </multiselect>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import CloseIcon from 'vue-material-design-icons/Close';
import Multiselect from 'vue-multiselect';
import {mapGetters} from 'vuex';

export default {
  props: {
    value: [Object, String],
    placeholder: {type: String, default: ''},
  },
  data() {
    return {
      searchRan: false,
    };
  },
  computed: {
    ...mapGetters(['burialLocationsLoadingState', 'burialLocationsState']),
    options() {
      return this.burialLocationsState.map(data => {
        return {id: data.place_id || data.cemetery_id, ...data};
      });
    },
  },
  methods: {
    search: debounce(function (query) {
      this.searchRan = false;
      if (query) {
        this.$store.dispatch('searchBurialLocationAction', {q: query}).then(() => {
          this.searchRan = true;
        });
      }
    }, 300),
    onSelect(value) {
      this.$emit('select', value);
    },
    clear() {
      this.onSelect({});
    },
    clearOptions() {
      this.$store.commit('setBurialLocationsState', []);
    },
    isMultiselectOpened() {
      return this.$refs['place-input'] && this.$refs['burial-location'].isOpen;
    },
  },
  components: {Multiselect, CloseIcon},
};
</script>

<style scoped></style>
