<template>
  <div class="save-mention-modal-content">
    <div class="heading">
      <div class="text-lg bold">Save Record</div>
      <close-icon class="close-icon" @click="closeModal"></close-icon>
    </div>
    <div class="content">
      <template v-if="!savedMentionId">
        <div class="label text-md bold">Save to Inbox</div>
        <div class="text-sm sublabel">Save this record to your inbox and connect it to your family tree later.</div>

        <mcr-button :loading="loadingToInbox" :disabled="loadingToPerson" @click="saveToInboxClick"
          >Save to Inbox</mcr-button
        >

        <hr />
      </template>

      <div class="label text-md bold">Save to Family Tree</div>

      <family-tree-people-multiselect-container
        ref="people-select"
        class="bordered"
        :family-tree-id="null"
        :value="selectedPerson"
        :multiple="false"
        @select="peopleSelect"
        @clear="clearPeople"
      ></family-tree-people-multiselect-container>

      <mcr-loading-indicator v-if="loadingFullPersonData" :loading="true"></mcr-loading-indicator>
      <div v-else-if="comparisonTable.length || relativesTable.length">
        <div class="bold table-label" v-if="isRelativesTableShown">Select relationships to save</div>
        <div class="bold table-label" v-else>Select the information to save</div>

        <progress-bar :value="progressValue" v-if="progressValue"></progress-bar>

        <compare-relatives-table
          v-show="isRelativesTableShown"
          :relatives-table="relativesTable"
          :person-relatives="personRelatives"
          ref="relatives"
        ></compare-relatives-table>
        <compare-persons-table
          :rows="comparisonTable"
          ref="table"
          :save-mode="true"
          v-show="!isRelativesTableShown"
        ></compare-persons-table>
      </div>

      <div class="save-to-tree-button">
        <mcr-button
          class="save"
          v-if="showSaveToPerson"
          @click="onSaveNoData"
          :loading="loadingToPerson"
          :disabled="disableSave"
          >Save to Family Tree</mcr-button
        >
        <mcr-button
          class="save"
          v-if="showSaveInformation"
          @click="onSave"
          :loading="loadingToPerson"
          :disabled="disableSave"
          >Save Information</mcr-button
        >
        <mcr-button class="save" v-else-if="showNext" @click="goNext">Next</mcr-button>
        <mcr-button
          class="save"
          v-else-if="showAddPersons"
          @click="onSave"
          :loading="loadingToPerson"
          :disabled="disableSave"
          >Add Persons</mcr-button
        >
        <mcr-button class="white" v-if="showBack" @click="goBack"
          ><arrow-left-icon :size="18" /><span>Back</span></mcr-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import mcrButton from '@common/elements/buttons/mcrButton';
import ProgressBar from '@common/elements/layouts/ProgressBar';
import network from '@common/network/network';
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';
import {isEmpty} from 'lodash';
import ArrowLeftIcon from 'vue-material-design-icons/ArrowLeft';
import CloseIcon from 'vue-material-design-icons/Close';

import FamilyTreePeopleMultiselectContainer from '@/components/common/inputs/FamilyTreePeopleMultiselectContainer';
import ComparePersonsTable from '@/components/common/tree/ComparePersonsTable';
import CompareRelativesTable from '@/components/common/tree/CompareRelativesTable';
import {createComparisonTable, createRelativesTable} from '@/components/common/tree/utils.personsCompare';

export default {
  data() {
    return {
      selectedPerson: null,
      selectedPersonFullData: null,
      mentionPerson: null,
      comparisonTable: [],
      relativesTable: [],
      personRelatives: [],
      loadingFullPersonData: false,
      loadingToPerson: false,
      loadingToInbox: false,
      isRelativesTableShown: false,
      mentionId: this.recordMentionId,
    };
  },
  props: {
    savedMentionId: {required: false, type: String},
    recordMentionId: {required: false, type: String},
    callback: {required: false, type: Function},
    source: {required: true, type: Object},
    matchPersonId: {required: true, type: [String, Number]},
  },
  created() {
    AnalyticsAmplitudeHandler.trackClickSaveRecordEvent(
      getRoutePageName(this.$route),
      this.source.country,
      this.source.mention_type_display
    );
  },
  mounted() {
    this.$refs['people-select'].autofocus();
  },
  computed: {
    showSaveInformation() {
      return this.comparisonTable.length && !this.relativesTable.length;
    },
    showSaveToPerson() {
      return !this.comparisonTable.length && !this.relativesTable.length;
    },
    showNext() {
      return this.comparisonTable.length && this.relativesTable.length && !this.isRelativesTableShown;
    },
    showBack() {
      return this.comparisonTable.length && this.isRelativesTableShown;
    },
    showAddPersons() {
      return this.isRelativesTableShown;
    },
    disableSave() {
      return !this.selectedPerson || this.loadingToInbox || this.loadingFullPersonData;
    },
    progressValue() {
      if (this.relativesTable.length && this.comparisonTable.length) {
        return this.isRelativesTableShown ? 100 : 50;
      }
      return 0;
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    peopleSelect(item) {
      this.selectedPerson = item;
      this.loadingFullPersonData = true;
      network.familyTreePerson
        .comparePersons({person_id: item.object_id, match_person_id: this.matchPersonId})
        .then(response => {
          const {person, match_person, mention_id} = response;
          this.mentionId = mention_id;
          const comparisonTable = createComparisonTable(person, match_person, mention_id);
          if (comparisonTable.filter(item => item.canSave).length) {
            this.selectedPersonFullData = person;
            this.mentionPerson = match_person;
            this.comparisonTable = comparisonTable;
          }
          this.personRelatives = person.relatives;
          this.relativesTable = createRelativesTable(person.relatives, match_person.relatives);
          this.isRelativesTableShown = !this.comparisonTable.length && this.relativesTable.length;
        })
        .catch(err => {
          this.selectedPersonFullData = null;
          this.mentionPerson = null;
          this.comparisonTable = null;
          this.$toasted.error('Failed to load person data.');
        })
        .finally(() => {
          this.loadingFullPersonData = false;
        });
    },
    clearPeople() {
      this.selectedPerson = null;
    },
    saveToInboxClick() {
      this.loadingToInbox = true;
      this.$store
        .dispatch('saveMentionToFamilyTreeAction', {
          family_tree_id: 'my',
          mention_id: this.mentionId,
        })
        .then(() => {
          const action = {text: 'Go to Inbox', push: {name: 'record-inbox'}};
          this.$toasted.success(`Record saved to inbox`, {action});
          this.closeModal();
        })
        .catch(() => {
          this.loadingToInbox = false;
          this.$toasted.error('Save to Inbox failed. Please try again later.');
        });
      AnalyticsAmplitudeHandler.trackSaveRecordEvent(
        getRoutePageName(this.$route),
        true,
        this.source.country,
        this.source.mention_type_display
      );
    },
    goNext() {
      this.isRelativesTableShown = true;
    },
    goBack() {
      this.isRelativesTableShown = false;
    },
    onSaveNoData() {
      if (!this.selectedPerson) {
        return;
      }
      this.loadingToPerson = true;
      this.saveRecordToFamilyTreePerson();
    },
    onSave() {
      if (!this.selectedPerson) {
        return;
      }
      this.loadingToPerson = true;
      const personData = this.$refs.table.getDataForSave();
      const params = {id: this.selectedPerson.object_id, data: personData};
      const relativesData = this.$refs.relatives.getDataForSave();
      const personPromise = isEmpty(personData)
        ? Promise.resolve()
        : this.$store.dispatch('familyTreePersonAcceptDataAction', params);
      const relativesPromise = isEmpty(relativesData)
        ? Promise.resolve()
        : this.$store.dispatch('importRelativesToFamilyTreePerson', {
            id: this.selectedPerson.object_id,
            new_relatives: relativesData,
          });
      Promise.all([personPromise, relativesPromise])
        .then(responses => {
          this.saveRecordToFamilyTreePerson();
        })
        .catch(err => {
          this.$toasted.error('Failed to save data to person.');
          this.loadingToPerson = false;
        });
    },
    saveRecordToFamilyTreePerson() {
      this.$store
        .dispatch('saveMentionToFamilyTreeAction', {
          person_id: this.selectedPerson.object_id,
          mention_id: this.mentionId,
          saved_mention_id: this.savedMentionId,
        })
        .then(() => {
          if (this.callback) {
            this.closeModal();
            return this.callback();
          }
          const personName = this.selectedPerson.full_name || 'Person';
          const route = {
            name: 'familytree-profile-details',
            params: {id: this.selectedPerson.family_tree.id, personId: this.selectedPerson.object_id},
            query: {tab: 'details'},
          };
          const action = {text: 'View Profile', push: route};
          this.$toasted.success(`Record saved to ${personName}`, {action});
          this.closeModal();
        })
        .finally(() => {
          this.loadingToPerson = false;
        });
      AnalyticsAmplitudeHandler.trackSaveRecordEvent(
        getRoutePageName(this.$route),
        false,
        this.source.country,
        this.source.mention_type_display
      );
    },
  },
  name: 'SaveMentionModalContent',
  components: {
    ArrowLeftIcon,
    CloseIcon,
    FamilyTreePeopleMultiselectContainer,
    mcrButton,
    ComparePersonsTable,
    CompareRelativesTable,
    ProgressBar,
  },
};
</script>

<style lang="scss" scoped>
.save-mention-modal-content {
  width: $reading-max-width;

  .heading {
    color: $neutral-600;
  }

  .sublabel {
    margin-bottom: 16px;
    color: $neutral-500;
  }

  hr {
    border-color: $neutral-200;
    margin: 20px 0;
  }

  .people-multiselect {
    margin-top: 8px;
    margin-bottom: 16px;
  }
  .people-multiselect + .save-to-tree-button {
    margin-top: 80px;
  }

  .save-to-tree-button {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    .mcr-button {
      min-width: 100px;
    }
  }

  .table-label {
    margin: 16px 0;
  }

  .progress-bar {
    margin-bottom: 24px;
  }

  .comparison-table,
  .compare-relatives-table {
    margin: 8px 0 32px 0;
  }

  @media only screen and (max-width: $main-menu-breakpoint) {
    width: 100%;
  }
}
</style>
