<template>
  <form-display v-bind="$attrs" v-on="$listeners" :active-tab="activeTab" :filters="filters"></form-display>
</template>

<script>
import FormDisplay from '@common/pages/searches/FormDisplay';
import {TAB_ID_FULL_TEXT, TAB_ID_INDEXED_RECORDS, TAB_ID_SOURCES} from '@common/utils/consts.search';

export default {
  inheritAttrs: false,
  props: {
    activeTab: String,
    options: Object,
    formData: Object,
  },
  computed: {
    filtersAvailable() {
      const mapping = {
        [TAB_ID_INDEXED_RECORDS]: this.filtersAvailableIndexed,
        [TAB_ID_FULL_TEXT]: this.filtersAvailableFullText,
        [TAB_ID_SOURCES]: this.filtersSources,
      };
      return mapping[this.activeTab];
    },
    filtersAvailableIndexed() {
      return [
        {label: 'First Name', keys: ['first_name']},
        {label: 'Last Name', keys: ['surname']},
        {label: 'Place', keys: ['auto_place']},
        {label: 'Birth', keys: ['birth_year', 'birth_location']},
        {label: 'Death', keys: ['death_year', 'death_location']},
        {label: 'Residence', keys: ['residence_location']},
        {label: 'Burial Location', keys: ['burial_location']},
        {label: 'Collection', keys: ['collection']},
      ];
    },
    filtersAvailableFullText() {
      return [
        {label: 'First Name', keys: ['q']},
        {label: 'Last Name', keys: ['last_name']},
        {label: 'Collection', keys: ['collection']},
      ];
    },
    filtersSources() {
      return [
        {label: 'Keyword', keys: ['q']},
        {label: 'Place', keys: ['auto_place']},
        {label: 'Publication Year', keys: ['publication_year']},
        {label: 'Collection', keys: ['collection']},
      ];
    },
    filters() {
      let res = this.filtersAvailable.map(item => {
        const values = item.keys.map(key => this.getValue(key, this.formData[key])).filter(v => !!v);
        return {...item, values};
      });
      return res.filter(item => item.values.length);
    },
  },
  methods: {
    getValue(key, value) {
      const mapping = {
        residence_location: this.getAutoPlaceDisplay,
        gender: this.getGenderDisplay,
        burial_location: this.getBurialLocationDisplay,
        collection: this.getCollectionDisplay,
        auto_place: this.getAutoPlaceDisplay,
        birth_location: this.getAutoPlaceDisplay,
        death_location: this.getAutoPlaceDisplay,
      };
      const method = mapping[key];
      return method ? method(value) : value;
    },
    getGenderDisplay(gender) {
      const genders = this.options && this.options.genders;
      const gen = genders ? genders.find(c => c.value == gender) : null;
      return gen && gen.name;
    },
    getBurialLocationDisplay(location) {
      if (!location) {
        return '';
      }
      return location.cemetery_id || location.cemetery_place_id || location.name ? location.name || ' ' : '';
    },
    getCollectionDisplay(collection) {
      return collection ? collection.title_en || collection.title_ch : '';
    },
    getAutoPlaceDisplay(place) {
      return place && place.full_address_en;
    },
  },
  components: {FormDisplay},
  name: 'FormDisplayContainer',
};
</script>

<style scoped></style>
